import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const Pricing = () => {
  return (
    <>
    <Header/>
    <div className="pricing">
        <div className="container">
          <h2>Pricing</h2>
          <div className="price-card" data-aos="zoom-in" data-aos-offset={300} data-aos-easing="ease-in-sine" data-aos-duration={500}>
            <p>1% percentage<br />
              capped at 500 Naira max </p>
            <p>Or</p>
            <h2>₦30 Flat Rate</h2>
            <img src="images/price-line.png" alt="" />
            <p>No setup cost <br />
              Accept Mastercard, Visa, USSD and Bank transfer <br />
              Fees per transaction capped at NGN 500</p>
          </div>
        </div>
      </div>
    <Footer/>
    </>
  )
}
