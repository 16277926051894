import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const Developers = () => {
  return (
    <>
      <Header />

      {/* <div className='developers' style={{ height: '10em' }}> */}
        <section className="integrate developers">

          <div className="integrate-container">
            <h2 style={{ textAlign: 'center' }}>Easy to Integrate APIs for
              Developers</h2>
            <p style={{ textAlign: 'center' }}>Payvessel helps developers with the ease of integrating our APIs for ease of usage on their
              projects.</p>
            {/* <li style="list-style: unset;" data-aos="zoom-in-up" data-aos-duration="2000">Make Quick Transfers</li> */}
            <img src="images/documentation.png" alt="" className="set-img-2" />
          </div>
        </section>
      {/* </div> */}

      <Footer />
    </>
  )
}
