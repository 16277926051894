import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const Error404Screen = () => {
  return (

    <>
     <Header/>

     <div className="container">
    <div className="hero" data-aos="zoom-in" data-aos-offset={300} data-aos-easing="ease-in-sine">
      {/* <div className="flex-2">
        <div className="flex line">
        </div> */}
        <div className="aside-info">
          {/* <h1>404 <span className="prm-color"> Page</span>
            Not Found</h1> */}

            <div className='error-container'>

            <img src="images/404_image.png" alt='Error 404'/>
            <p>Page Not Found</p>

            <div className="btn-section flex" data-aos="zoom-in" data-aos-duration={2000}>
            <div>
              <a href="/"><button className="btn">Go Back</button></a>
            </div>
          </div>

            </div>


    
        </div>
      {/* </div> */}
    </div>
  </div>
  <Footer/>

    
    </>
    
  
  )
}
