import React from 'react';
import { Link } from "react-router-dom";


export const Footer = () => {
  return (
    <footer className="vessel">
    <div className="container">
      <div className="grid">
        <div>
          <div className="logo">
            <img src="images/payvessel logo no bg white 1.png" alt="payvessel logo" />
          </div>
          <p>Payvessel offers businesses a fast and secure way to get paid whilst helping them scale their business with analytics tools to monitor the growth of their business.</p>
          <div className="flex icon">
            <a href="https://www.facebook.com/payvessel" target="_blank" rel="noopener noreferrer"><img src="images/fb-svg.svg" alt="" /></a>
            <a href="https://www.instagram.com/payvessel/" target="_blank" rel="noopener noreferrer"><img src="images/insta-svg.svg" alt="" /></a>
            <a href><img src="images/ln-svg.svg" alt="" /></a>
          </div>
        </div>
        <div>
          <h3>Payvessel</h3>
          <p><Link to="/">Home</Link></p>
          <p><Link to="/pricing">Pricing</Link></p>
          <p><Link to="/about">About Us</Link></p>
          <p><Link to="/faqs">FAQs</Link></p>
        </div>
        <div>
          <h3>Developer</h3>
          <p><a href>Documentation</a></p>
        </div>
        <div>
          <h3>Contact</h3>
          <p><a href="mailto:support@payvessel.com" target="_blank" rel="noopener noreferrer">support@payvessel.com</a></p>
          <p><a href="https://www.facebook.com/payvessel" target="_blank" rel="noopener noreferrer">Facebook </a></p>
          <p><a href="https://www.instagram.com/payvessel/" target="_blank" rel="noopener noreferrer">Instagram </a></p>
        </div>
      </div>
      <hr />
      <div className="flex copy-right">
        <p>©2024 Payvessel. All rights reserved</p>
        <div className="flex">
          <p><Link to="/privacy"> Privacy &amp; Policy</Link></p>
          <p><Link to="/terms"> Terms &amp; Condition </Link></p>
        </div>
      </div>
    </div>
  </footer>
  )
}
