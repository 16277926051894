import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const Home = () => {
  return (
    <>
    <Header/>
    <div className="container">
      <div className="hero flex">
        <div className="flex-2">
          <div className="flex line">
            <img src="images/line.png" alt="line" width="150px" height="1px" />
            <p style={{fontSize: '14px'}}> Secure and Reliable Payment Gateway</p>
          </div>
          <div className="aside-info">
            <h2>Building <span className="prm-color"> Confidence</span> Between Businesses
              and Customers</h2>
            <p>Payvessel provides Nigerian businesses with a means
              to receive payments from customers with ease.</p>
            <div className="btn-section flex">
              <div>
                <a href="https://app.payvessel.com/register"><button className="btn prm-color"> Create An Account <img src="images/arrow.png" alt="" /></button></a>
              </div>
              <div>
                <a href="https://app.payvessel.com"><button className=" btn btn-dark"> Login</button></a>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="hero-img"> */}

        <img className="heropic" src="images/hero-pic.png" alt="hero" />

        {/* </div> */}
      </div>
      <div className="network">
        <img src="images/network 2.png" alt="" />
      </div>
      <section className="payment">
        <div className="center">
          <h2>Recieving Payments Made easy.</h2>
          <p>Getting paid shouldn’t be an hindrance to your business’s success.</p>
        </div>
        <div className="flex cards">
          <div className="card one">
            <div className="flex">
              <img className="set-img" src="images/01.png" alt="" />
              <img src="images/line4.png" alt="" height="60px" />
              <img className="set-img" src="images/timer.png" alt="" />
            </div>
          </div>
          <div className="card two">
            <h3>Get Started in Minutes</h3>
            <p>Register a payvessel account in minutes to integrate our system into
              your business and get paid without hassles.</p>
          </div>
        </div>
        <div className="flex">
          <div className="card one">
            <div className="flex">
              <img className="set-img" src="images/02.png" alt="" />
              <img src="images/line4.png" alt="" height="60px" />
              <img className="set-img" src="images/moneys.png" alt="" />
            </div>
          </div>
          <div className="card two">
            <h3>Track Payments</h3>
            <p>Monitor and access your customers payments in real-time with
              payvessel.</p>
          </div>
        </div>
      </section>
    </div>
    <div className="learn-more">
      <div className="learn-more-container">
        <div className="learn-more-content">
          <h1>What is Payvessel?</h1>
          <p>Payvessel is a payment gateway service that enables merchants to receive payments from customers. </p> <br />
          <p>Payvessel offers businesses a fast and secure way to get paid whilst helping them scale their business with analytics tools to monitor the growth of their business. </p> <br />
          <p> Payvessel also makes it easier for customers to pay you leading to smooth shopping experience which enhances customer retention for your business.</p>
          <a href="/about"><button className="learn-more-btn btn wide"  style={{marginTop: '20px'}}>Learn more</button></a>
        </div>
        <div className="learn-more-image">
          <img src="images/learnmore.png" alt="" />
        </div>
      </div>
    </div>
    <section className="we-are-section">
      <div className="we-are-container">
        <h3 style={{margin: '70px 0px'}}>We are...</h3>
        <div className="we-are-flex">
          <div className="we-are-card">
            <span className="we-are-tag">
              <img src="images/03.png" alt="" />
            </span>
            <div className="we-are-icon">
              <img src="images/reputation.svg" alt="" />
              <h3>Reliable</h3>
            </div>
            <div className="we-are-content">
              Receive payments seamlessly with 
              payvessel without inefficiencies
              that cripples businesses.
            </div>
          </div>
          <div className="we-are-card">
            <span className="we-are-tag">
              <img src="images/04.png" alt="" />
            </span>
            <div className="we-are-icon">
              <img src="images/shield.svg" alt="" />
              <h3>Safe &amp; Secure</h3>
            </div>
            <div className="we-are-content">
              Payvessel keeps your transactions safe and secures all your businesses' data with high end encryption.
            </div>
          </div>
          <div className="we-are-card">
            <span className="we-are-tag">
              <img src="images/05.png" alt="" />
            </span>
            <div className="we-are-icon">
              <img src="images/stopwatch.svg" alt="" />
              <h3>Fast</h3>
            </div>
            <div className="we-are-content">
              Payvessel is built to not delay your
              transactions. Receive payments in
              minutes with less delay.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="platform-section">
      <div className="platform-container">
        <div className="platform-img">
          <div className="platform-img-shadow">
            <img src="images/platform-pic.png" alt="team" />
          </div>
        </div>
        <div className="platform-text">
          <h2>A platform created
            to build successful
            businesses.</h2>
          <p>Payvessel helps you skyrocket your
            Nigerian business to the top.</p>
          <a href="/contact"><button className="btn wide platform-btn ">Contact
              Us</button></a>
        </div>
      </div>
    </section>
    <section className="integrate">
     
      <div className="feedback">
        <div className="feedback-container">
          <div className="feed-bk-txt">
            <h2>Customers Feedback</h2>
            <img src="images/Line5.png" alt="" className="line" />
          </div>
          <div className="feedback-cards-list ">
            <div className="feedback-card ">
              <div className="feedback-user-info">
                <div className="feedback-user-img">
                  <img src="/images/musa.png" alt="musa" />
                </div>
                <div className="feedback-user-data">
                  <h3>Ganiyu Musa B.</h3>
                  <p>CEO Msorg Developers LTD</p>
                </div>
              </div>
              <div className="feedback-content">
                <p>Wonderful experience so far!  Since i started using payvessel i have been able to recieve payment from customers with ease.Also my Business Growth is Insane!
                </p>
              </div>
            </div>
            <div className="feedback-card">
              <div className="feedback-user-info">
                <div className="feedback-user-img">
                  <img src="/images/seun.png" alt="seun" />
                </div>
                <div className="feedback-user-data">
                  <h3>Oke Oluwaseun M.</h3>
                  <p>CEO SuperVas Technology</p>
                </div>
              </div>
              <div className="feedback-content">
                <p>I signed up being skeptical of this payment service but i must say kudos to y’all at payvessel you’ve  made my life very easy andmy business is doing great. “Skyrocket” indeed. :)</p>
              </div>
            </div>
            <div className="feedback-card">
              <div className="feedback-user-info">
                <div className="feedback-user-img">
                  <img src="/images/mercy.png" alt="mercy" />
                </div>
                <div className="feedback-user-data">
                  <h3>Macy Grace</h3>
                  <p>CEO Insurance LTD</p>
                </div>
              </div>
              <div className="feedback-content">
                <p>One of the best payment gateways out here infact it’s the best i’ve ever used. Easy to navigate, clean user interface, user friendly and not overly complicated like i have used in the past.</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <section className="jet">
      <div className="jet-container">
        <div className="bg-shadow" />
        <div className="start-jet ">
          <div className="start-jet-content">
            {/*<img src="images/jet.png" alt="" class="jet-img">*/}
            <h2>Get Started With Payvessel Now</h2>
            <p>Create an account and instantly start accepting payments,
              selling your beautiful products online and building financial tools.</p>
            <div className="start-jet-buttons">
              <a href="/register" className="start-jet-register">
                <div> Create an account</div>
                <span> <img src="/images/arrow-dark.svg" alt=""/></span>
              </a>
              <a href="/contact" className="start-jet-contact"> Contact Us </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </>
  )
}
