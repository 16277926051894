import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const Contact = () => {
  
  const selectRandomSupportNumber = () => {
    window.location.href = 'mailto:support@payvessel.com';
  };


  return (
    <>
    <Header/>
    <div className="contact">
        <div className="container">
          <h2 className="contact-header">Contact Us</h2>
          <section className="jet" data-aos="zoom-in-down" data-aos-duration={1500}>
            <div className="container">
              <div className="start-jet two flex">
                <div data-aos="zoom-out-up"className="flex-contact" data-aos-duration={2000}>

                  <img src="images/contact-pic.png" alt='contact' />

                  <div>
                  <h3>Need Help?</h3>
                  <h3>Talk to Payvessel Support</h3>
                  <p>Do you have general enquiries or need support? Please send us a quick e-mail</p>
                  <div className="form-control">
                    <button onClick={selectRandomSupportNumber} style={{cursor:"pointer"}}>Contact Support</button>
                  </div>
                  </div>

                </div>
              </div>
              <div className="bg-shadow two" />
            </div>
          </section>
         
        </div>
      </div>
    <Footer/>
    </>
  )
}
