import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const Terms = () => {
  return (
    <>
    <Header/>
    <div className="terms">
        <div className="container">
          <h1>PayVessel Terms and Conditions</h1>
          <h2>1. Introduction</h2>
          <p>1.1. Payvessel ("we," "our," or "us") is a payment gateway platform operating in Nigeria, providing payment processing services to merchants ("you" or "your"). By using our services, you agree to comply with these Terms and Conditions.</p>
          <p>1.2. These Terms and Conditions constitute a legally binding agreement between Payvessel and the merchant. Please read them carefully before using our platform.</p>
          <h2>2. Services Offered</h2>
          <p>2.1. Payvessel offers payment processing services, allowing merchants to accept payments from customers using various payment methods, including credit cards, debit cards, and electronic funds transfers.</p>
          <p>2.2. We reserve the right to update, modify, or discontinue our services at any time, with or without notice.</p>
          <h2>3. Merchant Responsibilities</h2>
          <p>3.1. By using our services, you agree to:</p>
          <p>Provide accurate and up-to-date information about your business and payment processing needs.</p>
          <p>Comply with all applicable laws and regulations, including those related to data privacy and consumer protection.</p>
          <p>Safeguard your login credentials and account information.</p>
          <h2>4. Payment Processing</h2>
          <p>4.1. Payvessel will process payments on behalf of the merchant in accordance with the terms of the merchant agreement.</p>
          <p>4.2. Payment processing fees and settlement times may vary. Please refer to your merchant agreement for specific details.</p>
          <h2>5. Security</h2>
          <p>5.1. We employ industry-standard security measures to protect your data. However, you are responsible for maintaining the security of your customer data and compliance with Payment Card Industry Data Security Standard (PCI DSS) requirements.</p>
          <h2>6. Data Privacy</h2>
          <p>6.1. Payvessel will collect and process personal and transactional data in accordance with applicable privacy laws and our Privacy Policy.</p>
          <h2>7. Dispute Resolution</h2>
          <p>7.1. In the event of a dispute, both parties agree to engage in good faith negotiations to resolve the issue.</p>
          <h2>8. Termination</h2>
          <p>8.1. Either party may terminate the agreement with written notice, subject to the terms of the merchant agreement.</p>
          <h2>9. Limitation of Liability</h2>
          <p>9.1. Payvessel's liability is limited to the extent permitted by law. We are not responsible for any indirect, incidental, or consequential damages.</p>
          <h2>10. Governing Law</h2>
          <p>10.1. These Terms and Conditions are governed by and construed in accordance with the laws of Nigeria. Any disputes will be subject to the exclusive jurisdiction of the Nigerian courts.</p>
          <h2>11. Changes to Terms and Conditions</h2>
          <p>11.1. Payvessel reserves the right to update these Terms and Conditions at any time. You will be notified of any changes, and your continued use of our services constitutes acceptance of the revised terms.</p>
          <h2>12. Contact Information</h2>
          <p>12.1. For questions or concerns about these Terms and Conditions or our services, please contact us at [contact email or address].</p>
          <h2>13. Entire Agreement</h2>
          <p>13.1. These Terms and Conditions constitute the entire agreement between the parties and supersede all prior agreements and understandings.</p>
          <p>By using PayVessel's payment gateway platform, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our services.</p>
          <p>Date of Last Update: September 1, 2023</p>
        </div>
      </div>
    <Footer/>
    </>
  )
}
