import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const Faqs = () => {

    const [accordionItems, setAccordionItems] = useState([
        { title: "Does Payvessel have card payment option?", content: "Payvessel does not have a card payment option hence you can only integrate our virtual account option into your business which allows you receive payments using bank transfers.", isOpen: false },
        { title: "How long does it take to recieve payment from my payvessel account to my bank account?", content: "Payvessel settles your money in your account. When you click the withdrawal button your money will be settled in your bank account the next working day.", isOpen: false },
        { title: "Do i have to pay to integrate Payvessel’s API?", content: "", isOpen: false },
        { title: "Do i need to be an experienced developer to use Payvessel?", content: "", isOpen: false },
        { title: "Can i open a payvessel account without my business being registered?", content: "", isOpen: false },
   
      ]);

      
  const toggleAccordion = (title) => {
    const updatedItems = accordionItems.map((item) => ({
      ...item,
      isOpen: item.title === title ? !item.isOpen : false,
    }));

    setAccordionItems(updatedItems);
  };

  return (
    <>
    <Header/>
    <div className="faqs">
        <div className="container">
          <h1 data-aos="fade-down" data-aos-duration={1000}>Frequently Asked Questions (FAQs)</h1>
          <div className="accordion">

              {accordionItems.map((item,index)=>
              <div className= {item.isOpen ? "accordion-card active" : "accordion-card" }  key={index}  onClick={() => toggleAccordion(item.title)}>
              <span className="flex"> <h3>{item.title}</h3> <img src={item.isOpen ? "images/minus.png" : "images/plus.png" } alt="" className="plus" /> </span>
              <p>{item.content}</p>
            </div>
              )}

          </div>
          <div className="more-qs" >
            <p>Have more questions?</p>
            <Link to="mailto:support@payvessel.com"><button className="btn btn-prm"  data-aos-duration={1500}>Contact Us</button></Link>
          </div>
        </div>
      </div>
    <Footer/>
    </>
  )
}
