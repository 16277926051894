import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const Privacy = () => {


  return (
    <>
    <Header/>
    <div className="privacy">
        <div className="container">
        <h1>Privacy Policy</h1>
        <p>Welcome to Payvessel, your trusted payment processing platform in Nigeria. At Payvessel, we are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you use our services.</p>
        <p>By using Payvessel, you consent to the practices described in this Privacy Policy. Please take a moment to read this document carefully.</p>
        
        <h2>1. Information We Collect</h2>
        <p>Payvessel collects data from you through our interactions and through our products for various purposes. To process data, we rely on your consent, legitimate interests, necessity to enter and perform contracts, and compliance with legal obligations. You have choices regarding the technology you use and the data you share. When asked to provide personal data, you can decline. However, some products require personal data to function, and if you choose not to provide it, you may not be able to use that product or feature.</p>
        <p><strong>Personal Information:</strong> When you sign up for Payvessel, we may collect your name, email address, phone number, and other personally identifiable information necessary for account creation and verification.</p>
        <p><strong>Passwords:</strong> Password hints and similar security information used for authentication and account access.</p>
        <p><strong>Transaction Information:</strong> We gather information about your transactions, including payment history, amounts, dates, and contribution details.</p>
        <p><strong>Device and Usage Information:</strong> We may collect information about the device you use to access Payvessel, such as your device's unique identifier, IP address, and browser type. We also track your usage of our platform to enhance our services.</p>
        <p><strong>Location Information:</strong> With your consent, we may collect your location data to provide location-based services and comply with regulatory requirements.</p>
        <p><strong>Feedback and Ratings:</strong> Information you provide to us and the content of messages you send to us, such as feedback, survey data, support messages, and product reviews.</p>
        
        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect for various purposes, including but not limited to:</p>
        <ul>
            <li><strong>Account Management:</strong> To create and manage your Payvessel account and provide customer support.</li>
            <li><strong>Transaction Processing:</strong> To process your payments and maintain transaction records.</li>
            <li><strong>Security:</strong> To detect and prevent fraudulent activities, unauthorized access, and other security issues.</li>
            <li><strong>Communication:</strong> To send you important updates, notifications, and promotional materials about our services.</li>
            <li><strong>Legal and Regulatory Compliance:</strong> To comply with applicable laws, regulations, and requirements in Nigeria.</li>
        </ul>
        
        <h2>3. Information Sharing</h2>
        <p>Payvessel may share your information with trusted third parties under the following circumstances:</p>
        <ul>
            <li><strong>Service Providers:</strong> We may engage third-party service providers to assist us in providing our services, such as processing payments or conducting security audits.</li>
            <li><strong>Legal Compliance:</strong> We may disclose your information if required by law or to protect our legal rights, defend against legal claims, or prevent harm to our users or the public.</li>
        </ul>
        
        <h2>4. Keeping of Personal Information</h2>
        <p>We keep information for as long as needed to provide our products, comply with legal obligations, or protect our interests or others'. We decide on the retention period case-by-case, considering factors such as:</p>
        <ul>
            <li>The need to operate or provide our products.</li>
            <li>The feature that uses the information and how it works.</li>
            <li>Compliance with certain legal obligations.</li>
            <li>Other legitimate purposes, such as preventing harm or protecting our rights.</li>
        </ul>
        
        <h2>5. Reasons We Share Personal Data</h2>
        <p>We may retain, access, transfer, disclose, and preserve personal data when necessary to:</p>
        <ul>
            <li>Comply with applicable law or respond to legal processes.</li>
            <li>Protect our customers, prevent fraud, or prevent serious harm.</li>
            <li>Maintain the security of our products and systems.</li>
            <li>Protect the rights or property of Payvessel, including enforcing terms of service.</li>
        </ul>
        
        <h2>6. How We Respond to Legal Requests, Comply with Applicable Law, and Prevent Harm</h2>
        <p>We access, preserve, use, and share your information in response to legal requests, such as search warrants or subpoenas. These requests come from civil litigants, law enforcement, or government authorities. We may also preserve your information for an extended period to promote safety, security, and integrity.</p>
        
        <h2>7. Your Privacy Choices</h2>
        <p>You have certain privacy rights, including:</p>
        <ul>
            <li><strong>Access and Correction:</strong> You can access and update your personal information in your Payvessel account by contacting <a href="support_page_link">Payvessel Support</a>.</li>
            <li><strong>Data Portability:</strong> You can request a copy of your personal data in a structured, commonly used, and machine-readable format.</li>
            <li><strong>Marketing Communications:</strong> You can delete your account or opt out of receiving promotional emails from Payvessel at any time.</li>
            <li><strong>Location Data:</strong> You can manage location data preferences through your device settings.</li>
        </ul>
        
        <h2>8. Data Security</h2>
        <p>We employ industry-standard security measures to protect your data, including encryption, access controls, and regular security assessments.</p>
        
        <h2>9. Children's Privacy</h2>
        <p>Payvessel does not knowingly collect personal information from individuals under the age of 18, except as permitted by the Nigeria Data Protection Act and other relevant laws. If you believe we have collected information from a child, please contact us immediately.</p>
        
        <h2>10. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of significant changes through our platform or other communication channels.</p>
        
        <h2>11. Governing Law</h2>
        <p>This Privacy Policy is governed by the Nigeria Data Protection Act (2023) and other relevant Nigerian laws, regulations, or international conventions applicable to Nigeria. If any provision is inconsistent with a law, regulation, or convention, that provision will be subject to the overriding law, regulation, or convention.</p>
        
        <h2>12. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or your privacy rights, please contact our Data Protection Officer at <a href="mailto:support@payvessel.com">support@payvessel.com</a>.</p>
        
        <div class="footer">
            <p>Thank you for choosing Payvessel.</p>
        </div>

        </div>
      </div>
    <Footer/>
    </>
  )
}
