import { Route, Routes } from "react-router-dom";
import { About } from "./Screens/About";
import { Contact } from "./Screens/Contact";
import { Developers } from "./Screens/Developers";
import { Error404Screen } from "./Screens/Error404Screen";
import { Faqs } from "./Screens/Faqs";
import { Home } from "./Screens/Home";
import { Pricing } from "./Screens/Pricing";
import { Privacy } from "./Screens/Privacy";
import { Terms } from "./Screens/Terms";

function App() {
  return (
          <Routes>
      
        <Route path='*' element={<Error404Screen />} />
        <Route path="/" element={<Home />} />
        <Route path="privacy/" element={<Privacy />} />
        <Route path="pricing/" element={<Pricing />} />
        <Route path="terms/" element={<Terms />} />
        <Route path="about/" element={<About />} />
        <Route path="contact/" element={<Contact />} />
        <Route path="faqs/" element={<Faqs />} />
        <Route path="developers/" element={<Developers />} />

       

        </Routes>   
  );
}

export default App;
