import React from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

export const About = () => {
  return (
    <>
    <Header/>
    <div className="about">
        <div className="container">
          <h2 className="heading">About Us</h2>
          <img src="images/about-pic.png" alt="about" />
          <h3>Who we are: Connecting Commerce through Innovation - Payvessel</h3>
          <p>At Payvessel, we are driven by a singular mission: to revolutionize the way businesses transact and consumers experience online payments. As a pioneering payment gateway startup, we have harnessed the power of cutting-edge technology to create a seamless, secure, and efficient ecosystem for digital transactions.</p>
          <br /> <br />
          <h3>Our Story: Navigating a Digital Frontier</h3>
          <p>Our journey began with a group of visionary minds who saw the challenges that businesses faced in the evolving digital landscape. The traditional payment methods were falling short in providing the flexibility, security, and speed required for modern commerce. With a deep-rooted commitment to innovation, we set out to bridge this gap and build a payment gateway that could truly empower businesses to thrive online.</p>
          <br /> <br /> 
          <h3>Our Vision: Transforming Transactions, Empowering Growth</h3>
          <p>At Payvessel, we envision a world where every business, regardless of size or industry, can effortlessly embrace online commerce. Our payment gateway serves as the conduit that transforms transactions from mere financial exchanges into opportunities for growth and expansion. By seamlessly connecting businesses with their customers, we empower them to reach new markets, nurture customer relationships, and unlock their full potential.</p>
        </div>
      </div>
    <Footer/>
    </>
  )
}
