import React from 'react'
import { Link } from "react-router-dom";
export const Header = () => {
    const openNav =()=>{

        let burger = document.getElementsByClassName('hamburger')[0];
        let ul = document.getElementsByClassName('nav-list')[0];
        if (burger) {
                ul.classList.toggle('active')
                burger.classList.toggle('active')
       
        }
    

    }

  return (

    
    <header className="pay-vessel">
        <div className="container-header">
          <nav className="navbar">
            <Link className="logo" to={"/"}>
              <img src="images/payvessel logo no bg 1.png" alt="payvessel logo" />
            </Link>
            <ul className="nav-list">
              <li><Link to="/">Home</Link></li>
              <li><Link to= "/pricing">Pricing</Link></li>
              <li><Link to="/developers">Developers</Link></li>
              <li><Link to="/about">Company</Link></li>
              <li><Link to="/faqs">FAQs</Link></li>
              <li><a href="https://app.payvessel.com" target="_blank" rel="noopener noreferrer">Login</a></li>
              <a className="btn first" href="https://app.payvessel.com/register" target="_blank" rel="noopener noreferrer"> Get Started</a>
            </ul>
            <div className="hamburger" onClick={openNav}>
              <span className="bar" />
              <span className="bar" />
              <span className="bar" />
            </div>
          </nav>
        </div>
      </header>
  )
}
